import { ref, watch } from '@vue/composition-api'
import { getIndex } from '@core/utils'
import store from '@/store'

export default function controller() {
  const blankItem = {
    id: null,
    configuration_name: null,
  }
  const form = ref(null)
  const items = ref([])
  const item = ref({})
  const tableColumns = [
    { text: 'Key', value: 'key' },
    { text: 'Type', value: 'type' },
    { text: 'Valeur', value: 'value' },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]
  const isSnackbarBottomVisible = ref(false)
  const snackBarMessage = ref('')
  const snackBarColor = ref('success')
  const isFormActive = ref(false)
  const deleteDialog = ref(false)
  const searchQuery = ref('')
  const totalItems = ref(0)
  const loading = ref(false)
  const loadingQuotation = ref(false)
  const loadingRate = ref(false)
  const loadingManuscript = ref(false)
  const loadingColor = ref(false)
  const options = ref({
    rowsPerPage: 10,
    page: 1,
    sortDesc: [false],
  })
  const configurations = ref({
    number_words_per_page: false,
  })
  const page = ref(1)
  const pageCount = ref(0)
  const selectedRows = ref([])
  const resetForm = () => {
    if (form.value) form.value.reset()
  }

  // new item
  const openDialog = val => {
    console.log(val)
    items.value.unshift(val)
    totalItems.value += 1
  }

  // fetch data
  const fetchItems = () => {
    isFormActive.value = false
    store
      .dispatch('configuration/fetchItems', {
        term: searchQuery.value,
        orderBy: options.value.sortBy ? (options.value.sortBy[0] || 'key') : 'key',
        // eslint-disable-next-line no-nested-ternary
        direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
        page: options.value.page,
        per_page: options.value.itemsPerPage,
      })
      .then(response => {
        const { data, total } = response.data
        if (data) {
          items.value = data.map(se => {
            // eslint-disable-next-line no-param-reassign
            se.loading = false

            return se
          })
          totalItems.value = total
        }

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
      .then(() => {
        loading.value = false
      })
  }
  const confirmDelete = val => {
    item.value = val
    deleteDialog.value = true
  }
  const deleteItem = () => {
    if (item) {
      store.dispatch('configuration/deleteItem', item.value.key)
        .then(response => {
          deleteDialog.value = false
          items.value.splice(getIndex(items.value, 'key', item.value.key), 1)
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {

        })
    }
  }

  watch([searchQuery, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchItems()
  })

  const onSubmit = it => {
    if (!it.key || !it.value) {
      isSnackbarBottomVisible.value = true
      snackBarMessage.value = 'Veuillez fournir tous les commentaires'

      return false
    }

    it.loading = true
    store.dispatch('configuration/addItem', it)
      .then(response => {
        it.loading = false
        if (response.data.error || (response.data.status && response.data.status === 'false')) {
          isSnackbarBottomVisible.value = true
          snackBarMessage.value = response.data.message

          return true
        }
      }).catch(error => {
        it.loading = false
        console.log('error', error)
        if (error.data.errors) {
          // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)errorMessages.value = { ...error.data.errors
        }
      })
  }
  const onSubmitMultiple = () => {
    /* if (!it.key || !it.value) {
      isSnackbarBottomVisible.value = true
      snackBarMessage.value = 'Veuillez fournir tous les commentaires'

      return false
    } */
    const updatedSettings = []
    for (const property in store.state.app.settings) {
      updatedSettings.push({ key: property, value: store.state.app.settings[property] })
    }
    loading.value = true
    store.dispatch('configuration/storeMultiple', { settings: updatedSettings })
      .then(response => {
        loading.value = false
        if (response.data.error || (response.data.status && response.data.status === 'false')) {
          isSnackbarBottomVisible.value = true
          snackBarMessage.value = response.data.message

          return true
        }
      }).catch(error => {
        loading.value = false
        console.log('error', error)
        if (error.data.errors) {
          // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)errorMessages.value = { ...error.data.errors
        }
      })
  }
  const onSubmitMultipleColors = () => {
    const updatedSettings = []
    for (const property in store.state.app.settings) {
      if (property === 'visual_idea_colors_ids' || property === 'author_copy_order_colors_ids' || property === 'communication_colors_ids' || property === 'manuscript_colors_ids') {
        updatedSettings.push({ key: property, value: store.state.app.settings[property].toString() })
      } else {
        updatedSettings.push({ key: property, value: store.state.app.settings[property] })
      }
    }
    loadingColor.value = true
    store.dispatch('configuration/storeMultiple', { settings: updatedSettings })
      .then(response => {
        loadingColor.value = false
        if (response.data.error || (response.data.status && response.data.status === 'false')) {
          isSnackbarBottomVisible.value = true
          snackBarMessage.value = response.data.message
          snackBarColor.value = 'red'

          return true
        }

        isSnackbarBottomVisible.value = true
        snackBarColor.value = 'success'
        snackBarMessage.value = 'Paramètres de couleurs mis à jour avec succès'
      }).catch(error => {
      loadingColor.value = false
        console.log('error', error)
        if (error.data.errors) {
          // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)errorMessages.value = { ...error.data.errors
        }
      })
  }
  const onSubmitPublication = () => {

    const updatedSettings = []
    updatedSettings.push({ key: 'publication_value', value: store.state.app.settings.publication_value })

    store.dispatch('configuration/storeMultiple', { settings: updatedSettings })
      .then(response => {
      }).catch(error => {
      })
  }
  const onSubmitMultipleManuscript = () => {
    /* if (!it.key || !it.value) {
      isSnackbarBottomVisible.value = true
      snackBarMessage.value = 'Veuillez fournir tous les commentaires'

      return false
    } */
    const updatedSettings = []
    for (const property in store.state.app.settings) {
      updatedSettings.push({ key: property, value: store.state.app.settings[property] })
    }
    loadingManuscript.value = true
    store.dispatch('configuration/storeMultiple', { settings: updatedSettings })
      .then(response => {
        loadingManuscript.value = false
        if (response.data.error || (response.data.status && response.data.status === 'false')) {
          isSnackbarBottomVisible.value = true
          snackBarMessage.value = response.data.message
          snackBarColor.value = 'red'

          return true
        }

        isSnackbarBottomVisible.value = true
        snackBarColor.value = 'success'
        snackBarMessage.value = 'Paramètres du manuscrit mis à jour avec succès'
      }).catch(error => {
        loadingManuscript.value = false
        console.log('error', error)
        if (error.data.errors) {
          // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)errorMessages.value = { ...error.data.errors
        }
      })
  }
  const onSubmitMultipleQuotation = () => {
    /* if (!it.key || !it.value) {
      isSnackbarBottomVisible.value = true
      snackBarMessage.value = 'Veuillez fournir tous les commentaires'

      return false
    } */
    const updatedSettings = []
    for (const property in store.state.app.settings) {
      updatedSettings.push({ key: property, value: store.state.app.settings[property] })
    }

    loadingQuotation.value = true
    store.dispatch('configuration/storeMultiple', { settings: updatedSettings })
      .then(response => {
        loadingQuotation.value = false
        if (response.data.error || (response.data.status && response.data.status === 'false')) {
          isSnackbarBottomVisible.value = true
          snackBarMessage.value = response.data.message
          snackBarColor.value = 'red'

          return true
        }
        isSnackbarBottomVisible.value = true
        snackBarColor.value = 'success'
        snackBarMessage.value = 'Paramètres de cotation mis à jour avec succès'
      }).catch(error => {
        loadingQuotation.value = false
        console.log('error', error)
        if (error.data.errors) {
          // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)errorMessages.value = { ...error.data.errors
        }
      })
  }
  const onSubmitRates = () => {
    const updatedSettings = []
    for (const property in store.state.app.settings) {
      updatedSettings.push({ key: property, value: store.state.app.settings[property] })
    }

    loadingRate.value = true
    store.dispatch('configuration/storeMultiple', { settings: updatedSettings })
      .then(response => {
        loadingRate.value = false
        if (response.data.error || (response.data.status && response.data.status === 'false')) {
          isSnackbarBottomVisible.value = true
          snackBarMessage.value = response.data.message
          snackBarColor.value = 'red'

          return true
        }
        isSnackbarBottomVisible.value = true
        snackBarColor.value = 'success'
        snackBarMessage.value = 'Taux de changes mis à jour avec succès'
      }).catch(error => {
      loadingRate.value = false
        console.log('error', error)
        if (error.data.errors) {
          // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)errorMessages.value = { ...error.data.errors
        }
      })
  }

  return {
    item,
    items,
    tableColumns,
    searchQuery,
    totalItems,
    onSubmit,
    onSubmitMultiple,
    onSubmitPublication,
    loadingManuscript,
    loadingQuotation,
    loadingColor,
    onSubmitMultipleColors,
    onSubmitMultipleManuscript,
    onSubmitMultipleQuotation,
    onSubmitRates,
    loading,
    options,
    configurations,
    selectedRows,
    isFormActive,
    blankItem,
    form,
    page,
    pageCount,
    snackBarColor,
    snackBarMessage,
    isSnackbarBottomVisible,
    deleteDialog,
    resetForm,
    openDialog,
    confirmDelete,
    deleteItem,
    fetchItems,
    loadingRate,
  }
}
