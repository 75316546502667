<template>
  <div id="user-list">
    <!-- configuration card -->
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <v-card :loading="loading">
          <v-card-title>
            {{ $t("Configurations des manuscrits") }}
          </v-card-title>
          <v-card-text class="pb-2">
            <v-list
              dense
              class="py-0"
            >
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("Montant dossiers rapides") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Montant minimum pour un dossier rapide.") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-text-field
                    v-model="$store.state.app.settings.minimum_amount_book"
                    outlined
                    dense
                    color="number"
                    hide-details
                    type="number"
                    style="width: 150px"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("Pages dossiers rapide.") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Nombre de pages minimal pour dossiers rapide.") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-text-field
                    v-model="$store.state.app.settings.minimum_pages_book"
                    outlined
                    dense
                    color="number"
                    hide-details
                    type="number"
                    style="width: 150px"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("Nombre d'exemplaires.") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Nombre d'exemplaires par défaut") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-text-field
                    v-model="$store.state.app.settings.dafault_value_copies"
                    outlined
                    dense
                    color="number"
                    hide-details
                    type="number"
                    style="width: 150px"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("Jours relance.") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Nombre de jours avant la relance.") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-text-field
                    v-model="$store.state.app.settings.date_planned_relaunch"
                    outlined
                    dense
                    color="number"
                    hide-details
                    type="number"
                    style="width: 150px"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("Prix des pages inconnu") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Prix des pages par défaut.") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-text-field
                    v-model="$store.state.app.settings.default_pages_price"
                    outlined
                    dense
                    color="number"
                    hide-details
                    type="number"
                    style="width: 150px"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("Pourcentage de copies") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Pourcentage du prix des copies") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-text-field
                    v-model="$store.state.app.settings.copies_percentage"
                    outlined
                    dense
                    hide-details
                    type="number"
                    style="width: 150px"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("Prix supplémentaire") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("prix supplémentaire") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-text-field
                    v-model="$store.state.app.settings.additionnal_copies_price"
                    outlined
                    dense
                    hide-details
                    type="number"
                    style="width: 150px"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("Status du manuscrit") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Status du manuscrit approuvé") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-select
                    v-model="$store.state.app.settings.manuscript_approuved_status"
                    :items="$store.state['app'].statuses.filter(ele => ele.status_type === 'manuscript')"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    hide-details="auto"
                    style="width: 150px"
                  >
                  </v-select>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("Status du signature publication") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Status du signature approuvé") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-autocomplete
                    v-model="$store.state.app.settings.publication_signed_status"
                    :items="$store.state['app'].statuses.filter(ele => ele.status_type === 'publication')"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    hide-details="auto"
                    style="width: 150px"
                    clearable
                  >
                  </v-autocomplete>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :loading="loadingManuscript"
              @click="onSubmitMultipleManuscript"
            >
              {{ $t("Save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <v-card :loading="loading">
          <v-card-title>
            {{ $t("Configurations des couleurs") }}
          </v-card-title>
          <v-card-text class="pb-2">
            <v-list
              dense
              class="py-0"
            >
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("Couleur BAT texte et couverture") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Status Couleur BAT texte et couverture") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-autocomplete
                    v-model="$store.state.app.settings.publication_bat_status"
                    :label="$t('Couleur')"
                    :items="$store.state['app'].colors"
                    item-text="color_name"
                    item-value="id"
                    outlined
                    dense
                    hide-details="auto"
                    :multiple="false"
                    clearable
                    style="width: 150px"
                  >
                    <template #item="{ item }">
                      <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                        <v-list-item-content>
                          <v-list-item-title>
                            <!--                            {{ item.color_name }}-->&nbsp;
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-item>
                    </template>
                    <template #selection="{ item }">
                      <v-item
                        :style="{'background-color': item.bg_color, 'color': item.txt_color }"
                        style="padding: 0px 4px;"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <!--                            {{ item.color_name }}-->&nbsp;
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-item>
                    </template>
                  </v-autocomplete>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("Couleur publication BOD") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Status publication BOD") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-autocomplete
                    v-model="$store.state.app.settings.publication_bod_status"
                    :label="$t('Couleur')"
                    :items="$store.state['app'].colors"
                    item-text="color_name"
                    item-value="id"
                    outlined
                    dense
                    hide-details="auto"
                    :multiple="false"
                    clearable
                    style="width: 150px"
                  >
                    <template #item="{ item }">
                      <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                        <v-list-item-content>
                          <v-list-item-title>
                            <!--                            {{ item.color_name }}-->&nbsp;
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-item>
                    </template>
                    <template #selection="{ item }">
                      <v-item
                        :style="{'background-color': item.bg_color, 'color': item.txt_color }"
                        style="padding: 0px 4px;"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <!--                            {{ item.color_name }}-->&nbsp;
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-item>
                    </template>
                  </v-autocomplete>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("Couleur blanc") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Couleur blanc") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-autocomplete
                    v-model="$store.state.app.settings.white_color_id"
                    :label="$t('Couleur')"
                    :items="$store.state['app'].colors"
                    item-text="color_name"
                    item-value="id"
                    outlined
                    dense
                    hide-details="auto"
                    :multiple="false"
                    clearable
                    style="width: 150px"
                  >
                    <template #item="{ item }">
                      <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                        <v-list-item-content>
                          <v-list-item-title>
                            <!--                            {{ item.color_name }}-->&nbsp;
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-item>
                    </template>
                    <template #selection="{ item }">
                      <v-item
                        :style="{'background-color': item.bg_color, 'color': item.txt_color }"
                        style="padding: 0px 4px;"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <!--                            {{ item.color_name }}-->&nbsp;
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-item>
                    </template>
                  </v-autocomplete>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("Couleur vert") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Couleur vert") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-autocomplete
                    v-model="$store.state.app.settings.green_color_id"
                    :label="$t('Couleur')"
                    :items="$store.state['app'].colors"
                    item-text="color_name"
                    item-value="id"
                    outlined
                    dense
                    hide-details="auto"
                    :multiple="false"
                    clearable
                    style="width: 150px"
                  >
                    <template #item="{ item }">
                      <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                        <v-list-item-content>
                          <v-list-item-title>
                            <!--                            {{ item.color_name }}-->&nbsp;
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-item>
                    </template>
                    <template #selection="{ item }">
                      <v-item
                        :style="{'background-color': item.bg_color, 'color': item.txt_color }"
                        style="padding: 0px 4px;"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <!--                            {{ item.color_name }}-->&nbsp;
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-item>
                    </template>
                  </v-autocomplete>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("Couleurs Visuel - idée") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Couleurs Visuel - idée") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-autocomplete
                    v-model="$store.state.app.settings.visual_idea_colors_ids"
                    :label="$t('Couleurs')"
                    :items="$store.state['app'].colors"
                    item-text="color_name"
                    item-value="id"
                    outlined
                    dense
                    hide-details="auto"
                    :multiple="true"
                    clearable
                    style="width: 150px"
                  >
                    <template #item="{ item }">
                      <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                        <v-list-item-content>
                          <v-list-item-title>
                            <!--                            {{ item.color_name }}-->&nbsp;
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-item>
                    </template>
                    <template #selection="{ item }">
                      <v-item
                        :style="{'background-color': item.bg_color, 'color': item.txt_color }"
                        style="padding: 0px 4px;"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <!--                            {{ item.color_name }}-->&nbsp;
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-item>
                    </template>
                  </v-autocomplete>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("Couleurs Cde exempl. auteur") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Couleurs Cde exempl. auteur") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-autocomplete
                    v-model="$store.state.app.settings.author_copy_order_colors_ids"
                    :label="$t('Couleurs')"
                    :items="$store.state['app'].colors"
                    item-text="color_name"
                    item-value="id"
                    outlined
                    dense
                    hide-details="auto"
                    :multiple="true"
                    clearable
                    style="width: 150px"
                  >
                    <template #item="{ item }">
                      <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                        <v-list-item-content>
                          <v-list-item-title>
                            <!--                            {{ item.color_name }}-->&nbsp;
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-item>
                    </template>
                    <template #selection="{ item }">
                      <v-item
                        :style="{'background-color': item.bg_color, 'color': item.txt_color }"
                        style="padding: 0px 4px;"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <!--                            {{ item.color_name }}-->&nbsp;
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-item>
                    </template>
                  </v-autocomplete>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("Couleurs de communications") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Couleurs de communications") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-autocomplete
                    v-model="$store.state.app.settings.communication_colors_ids"
                    :label="$t('Couleurs')"
                    :items="$store.state['app'].colors"
                    item-text="color_name"
                    item-value="id"
                    outlined
                    dense
                    hide-details="auto"
                    :multiple="true"
                    clearable
                    style="width: 150px"
                  >
                    <template #item="{ item }">
                      <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                        <v-list-item-content>
                          <v-list-item-title>
                            <!--                            {{ item.color_name }}-->&nbsp;
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-item>
                    </template>
                    <template #selection="{ item }">
                      <v-item
                        :style="{'background-color': item.bg_color, 'color': item.txt_color }"
                        style="padding: 0px 4px;"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <!--                            {{ item.color_name }}-->&nbsp;
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-item>
                    </template>
                  </v-autocomplete>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("Couleurs de manuscripts") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Couleurs de manuscripts") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-autocomplete
                    v-model="$store.state.app.settings.manuscript_colors_ids"
                    :label="$t('Couleurs')"
                    :items="$store.state['app'].colors"
                    item-text="color_name"
                    item-value="id"
                    outlined
                    dense
                    hide-details="auto"
                    :multiple="true"
                    clearable
                    style="width: 150px"
                  >
                    <template #item="{ item }">
                      <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                        <v-list-item-content>
                          <v-list-item-title>
                            <!--                            {{ item.color_name }}-->&nbsp;
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-item>
                    </template>
                    <template #selection="{ item }">
                      <v-item
                        :style="{'background-color': item.bg_color, 'color': item.txt_color }"
                        style="padding: 0px 4px;"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <!--                            {{ item.color_name }}-->&nbsp;
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-item>
                    </template>
                  </v-autocomplete>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :loading="loadingColor"
              @click="onSubmitMultipleColors"
            >
              {{ $t("Save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <v-card :loading="loadingQuotation">
          <v-card-title>
            {{ $t("Configurations des cotations") }}
          </v-card-title>
          <v-card-text class="pb-2">
            <v-list
              dense
              class="py-0"
            >
              <v-list-item
                class="px-0"
              >
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-2">
                    <v-list-item-title class="text-sm">
                      {{ $t("Cotation automatique") }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="mb-0">
                      {{ $t("Activez la cotation automatique.") }}
                    </v-list-item-subtitle>
                  </div>

                  <v-spacer></v-spacer>

                  <v-switch
                    v-model="$store.state.app.settings.automatic_quotation"
                    true-value="1"
                    false-value="0"
                  ></v-switch>
                </div>
              </v-list-item>
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("Tâches du gestionnaire") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Nombre de tâches par gestionnaire.") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-text-field
                    v-model="$store.state.app.settings.number_tasks_per_manager"
                    outlined
                    dense
                    color="number"
                    hide-details
                    type="number"
                    style="width: 150px"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("Tâches de l'assistant") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Nombre de tâches par assistant.") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-text-field
                    v-model="$store.state.app.settings.number_tasks_per_assistant"
                    outlined
                    dense
                    color="number"
                    hide-details
                    type="number"
                    style="width: 150px"
                  />
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :loading="loadingQuotation"
              dark
              @click="onSubmitMultipleQuotation"
            >
              {{ $t("Save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <v-card :loading="loadingRate">
          <v-card-title>
            {{ $t("Configurations des taux de change") }}
          </v-card-title>
          <v-card-text class="pb-2">
            <v-list
              dense
              class="py-0"
            >
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("Dollar US") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Taux de change pour le Dollar US.") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-text-field
                    v-model="$store.state.app.settings.us_dollar_rate"
                    outlined
                    dense
                    color="number"
                    hide-details
                    type="number"
                    style="width: 150px"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("AUD") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Taux de change pour le Dollar Australien") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-text-field
                    v-model="$store.state.app.settings.aud_dollar_rate"
                    outlined
                    dense
                    color="number"
                    hide-details
                    type="number"
                    style="width: 150px"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("GBP") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Taux de change pour la GBP") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-text-field
                    v-model="$store.state.app.settings.gbp_rate"
                    outlined
                    dense
                    color="number"
                    hide-details
                    type="number"
                    style="width: 150px"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-sm">
                    {{ $t("XAF/XOF") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mb-0">
                    {{ $t("Taux de change pour le F CFA") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-text-field
                    v-model="$store.state.app.settings.cfa_rate"
                    outlined
                    dense
                    color="number"
                    hide-details
                    type="number"
                    style="width: 150px"
                  />
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :loading="loadingRate"
              dark
              @click="onSubmitRates"
            >
              {{ $t("Save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="snackBarColor"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
  mdiContentSaveOutline,
  mdiImage,
} from '@mdi/js'
import {
  computed, ref, watch, onUnmounted,
} from '@vue/composition-api'
import axios from '@axios'

// sidebar
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import userStoreModule from './itemStoreModule'

import controller from './Controller'

export default {
  components: {
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'configuration'
    const importing = ref(false)
    const running = ref(false)
    const counter = ref(0)
    const perPage = ref(100)
    const n = ref(0)
    const datas = ref([])
    const total = 4687

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    store.state.app.settings.manuscript_approuved_status = parseInt(store.state.app.settings.manuscript_approuved_status, 10) || null
    store.state.app.settings.publication_signed_status = parseInt(store.state.app.settings.publication_signed_status, 10) || null
    store.state.app.settings.publication_bat_status = parseInt(store.state.app.settings.publication_bat_status, 10) || null
    store.state.app.settings.publication_bod_status = parseInt(store.state.app.settings.publication_bod_status, 10) || null
    store.state.app.settings.white_color_id = parseInt(store.state.app.settings.white_color_id, 10) || null
    store.state.app.settings.green_color_id = parseInt(store.state.app.settings.green_color_id, 10) || null
    const {
      item,
      items,
      tableColumns,
      searchQuery,
      totalItems,
      loading,
      options,
      configurations,
      selectedRows,
      isFormActive,
      snackBarMessage,
      snackBarColor,
      isSnackbarBottomVisible,
      openDialog,
      deleteItem,
      onSubmit,
      onSubmitMultiple,
      loadingManuscript,
      loadingColor,
      loadingQuotation,
      onSubmitMultipleColors,
      onSubmitMultipleManuscript,
      onSubmitMultipleQuotation,
      confirmDelete,
      deleteDialog,
      onSubmitRates,
      fetchItems,
      loadingRate,
    } = controller()

    const green = ref(null)
    const orange = ref(null)
    const red = ref(null)
    const yellow = ref(null)
    const black = ref(null)
    const white = ref(null)
    const cyan = ref(null)

    const importData = () => {
      if (counter.value <= total && running.value) {
        n.value += 1
        axios.post('/importAfaire11', {
          green: green.value,
          orange: orange.value,
          red: red.value,
          yellow: yellow.value,
          black: black.value,
          white: white.value,
          cyan: cyan.value,
          number_per_page: perPage.value,
          start_rows: counter.value,
        }).then(response => {
          counter.value = parseInt(counter.value) + parseInt(perPage.value)
          for (let i = 0; i < response.data.data.length; i++) {
            datas.value.push(response.data.data[i])
          }
          importData()
        }).catch(() => {
          // importing.value = false
        })
      } else {
        importing.value = false
      }
    }
    const startImportation = () => {
      running.value = true
      importing.value = true
      importData()
    }
    const stopImportation = () => {
      running.value = false
    }

    return {
      n,
      green,
      orange,
      red,
      yellow,
      black,
      white,
      cyan,
      startImportation,
      importing,
      counter,
      running,
      perPage,
      item,
      total,
      stopImportation,
      datas,
      tableColumns,
      searchQuery,
      totalItems,
      loading,
      options,
      configurations,
      mdiContentSaveOutline,
      isFormActive,
      selectedRows,
      snackBarMessage,
      snackBarColor,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      deleteDialog,
      confirmDelete,
      openDialog,
      deleteItem,
      avatarText,
      fetchItems,
      onSubmit,
      onSubmitMultiple,
      loadingManuscript,
      loadingQuotation,
      loadingColor,
      onSubmitMultipleColors,
      onSubmitMultipleManuscript,
      onSubmitMultipleQuotation,
      onSubmitRates,
      loadingRate,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
        mdiContentSaveOutline,
        mdiImage,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
